import { isMobile } from 'react-device-detect';

export const getDeepLinkPath = (pathname: string, defaultPath: string, search = '') => {
  const slug = pathname.split('/app/')[1] || 'open';
  const queryParams = search || '';
  let deepLinkPath = defaultPath;
  if (slug === 'plaid-oauth' && isMobile) {
    deepLinkPath = `dave://plaid-oauth${queryParams}`;
  } else if (isMobile) {
    const cleanedParams =
      queryParams?.indexOf('?') === 0 ? `&${queryParams.substring(1)}` : queryParams;
    deepLinkPath = `dave://UniversalLink/?uid=${slug}${cleanedParams}`;
  }
  return deepLinkPath;
};
