import { PageProps, graphql } from 'gatsby';

import { FC, useEffect } from 'react';

import { isMobile } from 'react-device-detect';

import { useAppStoreLink } from '../../components/app-store-buttons/helpers';
import { ContentfulFooterBlock } from '../../components/footer-block/footer-block.contentful';
import { PageNavbar } from '../../components/page-navbar';
import Seo from '../../components/seo/seo';
import { getDeepLinkPath } from '../../lib/deeplink';
import { ContentfulHero } from '../../modules/hero/hero.contentful';
import { ContentfulNavigation } from '../../modules/navigation/navigation.contentful';
import { normalizeSlug } from '../../utils/string';
import { Footer, Main } from '../{ContentfulMainTemplate.slug}';

const DeepLinkPage: FC<PageProps<Queries.DeepLinkQuery>> = ({
  data: { contentfulDeepLinkPage },
  location: { pathname, search },
}) => {
  const { seoMetaData, hero, pageNavbar, footer } = contentfulDeepLinkPage ?? {};
  const { APP_STORE_IOS } = useAppStoreLink();
  const deepLinkPath = getDeepLinkPath(pathname, APP_STORE_IOS, search);

  useEffect(() => {
    if (isMobile) {
      window.location.href = deepLinkPath;
    }
  }, [deepLinkPath]);

  return (
    <>
      <Seo
        title={seoMetaData?.title ?? ''}
        description={seoMetaData?.descriptionText ?? ''}
        meta={
          seoMetaData?.hideFromSearchEngines
            ? [
                {
                  name: 'robots',
                  content: 'noindex',
                },
              ]
            : []
        }
        pageName={seoMetaData?.pageName ?? ''}
      />
      <ContentfulNavigation />
      {pageNavbar && (
        <PageNavbar
          currentPath={pathname}
          links={
            pageNavbar.map(link => ({
              to: normalizeSlug(link?.to ?? '/'),
              text: link?.text ?? 'Lorem Ipsum',
            })) ?? []
          }
        />
      )}
      <Main isForm={false}>
        <ContentfulHero
          {...(hero as Queries.HeroModuleFragment)}
          primaryButton={{
            id: 'open-dave-app-button',
            text: hero?.primaryButton?.text ?? 'Open Dave app',
            path: deepLinkPath ?? '/',
          }}
        />
      </Main>
      <Footer>
        <ContentfulFooterBlock footer={footer as Queries.FooterModuleFragment['footer']} />
      </Footer>
    </>
  );
};

export default DeepLinkPage;

export const query = graphql`
  query DeepLink {
    contentfulDeepLinkPage(slug: { eq: "app" }) {
      seoMetaData {
        descriptionText
        hideFromSearchEngines
        metaSeo
        pageName
        title
      }
      pageNavbar {
        to
        text
      }
      hero {
        ...HeroModule
      }
      footer {
        __typename
        ...FooterText
        ...FooterLink
        ...FooterHeroModule
        ...FooterEmailBar
      }
    }
  }
`;
