import { PageProps, graphql } from 'gatsby';

import { FC } from 'react';

import DeepLinkPage from './app/[...slug]';

const DeepLinkAppPage: FC<PageProps<Queries.DeepLinkPageQuery>> = ({
  data,
  pageResources,
  ...props
}) => {
  const { contentfulDeepLinkPage } = data ?? {};
  const { json, ...restResources } = pageResources ?? {};
  return (
    <DeepLinkPage
      {...props}
      pageResources={{
        ...restResources,
        json: {
          ...json,
          data: { contentfulDeepLinkPage },
        },
      }}
      data={{ contentfulDeepLinkPage }}
    />
  );
};

export default DeepLinkAppPage;

export const query = graphql`
  query DeepLinkPage {
    contentfulDeepLinkPage(slug: { eq: "app" }) {
      seoMetaData {
        descriptionText
        hideFromSearchEngines
        metaSeo
        pageName
        title
      }
      pageNavbar {
        to
        text
      }
      hero {
        ...HeroModule
      }
      footer {
        __typename
        ...FooterText
        ...FooterLink
        ...FooterHeroModule
        ...FooterEmailBar
      }
    }
  }
`;
